import { getProgressBarDetails } from "examsbook-react-sdk/actions/getSelfLearningAction";
import { selfLearningVideoUrl } from "examsbook-react-sdk/actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getDefaultSupportDataAction, getSupportAction } from "examsbook-react-sdk/actions/getSupportAction";
import { useDefaultSeoSettings, useDyanmicSeoSettings, useSelfLearning } from "examsbook-react-sdk/custom-hooks";

 
function useSelfLearningContent(props = {}) {
  const {
    activeItem,
    selectedCourseId,
    videoProgress,
    handleVideoProgress,
    pdfOpen,
    videoRefresh,
    onVideoRefresh,
  } = props;
 
  const dispatch = useDispatch();
  const history = useHistory();
 
  const selectedVideo = useSelector((state) => state.selfLearningVideoUrl);
  const defaultVideo = activeItem
  const { isLoadingSelf, selfLearningData } = useSelfLearning(selectedCourseId);
  // Quiz
  const [showComponent, setShowComponent] = useState(false)
  const [quizIsStarted, setQuizStarted] = useState(false);
  const [showInstructionBox, setInstructionBox] = useState(true);
  // Video
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [currentVideo, setCurrentVideo] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [videoPlayback, setVideoPlayback] = useState(false);
  const currentVideoRef = useRef(null)
  const playerRef = useRef(null);
  const onReadyCalled = useRef(false);
  const user = useSelector((state) => state.user); 
  const { seoSettings } = useDefaultSeoSettings(user);
  const { dynamicSeoSettings } = useDyanmicSeoSettings(user);
 
  // Video Functions
  const handleVideoPlayback = (value) => {
    if (typeof value === "boolean") {
      setVideoPlayback(value)
    } else {
      setVideoPlayback(!videoPlayback);
    }
  };
 
  const closePopup = () => {
    setShowPopup(false);
  }
 
  const handleResume = () => {
        setShowPopup(false);
    setVideoPlayback(true);
    if (playerRef.current && currentVideo) {
      const videoPercent = videoProgress?.[currentVideo.videoKey] ?? currentVideo.percent;
      const totalDuration = playerRef.current.getDuration();
      const currentTime = (videoPercent / 100) * totalDuration;
      playerRef.current.seekTo(currentTime);
    }
  };
 
  const handleStartFromBeginning = () => {
    setShowPopup(false);
    setVideoPlayback(true);
    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  };
 
  const handleVideoOnReady = () => {
    setIsVideoLoaded(true);
    if (!onReadyCalled.current && currentVideo) {
      if (currentVideo.percent > 0 && currentVideo.percent < 100) {
        setVideoPlayback(false);
        setShowPopup(true);
      } else {
        setVideoPlayback(true);
      }
      onReadyCalled.current = true;
    }
  };
 
  const callProgressApi = ({ prevVideoKey, videoRefreshFn, videoRefreshVal }) => {
    const currentTime = playerRef.current?.getCurrentTime();
    const totalDuration = playerRef.current?.getDuration();
    const percentageWatched = Math.floor((currentTime / totalDuration) * 100);
 
    const data = {
      courseId: currentVideoRef.current?.courseId,
      sectionId: props?.sectionId,
      dataId: currentVideoRef.current?.id,
      percent: percentageWatched,
      isRead: percentageWatched === 100 ? true : false
    };
    const localVideoRefresh = videoRefreshVal ?? videoRefresh;
    const localVideoRefreshFn = videoRefreshFn ?? onVideoRefresh;
    dispatch(getProgressBarDetails(data));
    localVideoRefreshFn(!localVideoRefresh);
 
    if (percentageWatched > 0) {
      const localVideoKey = prevVideoKey ?? currentVideoRef.current.videoKey;
      handleVideoProgress(localVideoKey, percentageWatched);
    }
  };

  const dynamicVideoPlayerSettings = dynamicSeoSettings?.find((subValue) => subValue.key === "Video Player")?.value.toLowerCase().trim();
  const defaultVideoPlayerSettings = seoSettings?.["Video Player Controls"]?.find((subValue) => subValue.key === "Video Player")?.defaultValue;
 
  const handleCurrentVideo = () => {
    let videoData = {};
    let contentData = {};
 
    if (selectedVideo?.data?._id && selectedVideo?.sectionId) {
      const videoKey = `${activeItem?._id}-${selectedVideo.data._id}`;
      videoData = {
        id: selectedVideo.data._id,
        name: selectedVideo.data.name,
        percent: videoProgress?.[videoKey] ?? selectedVideo.data.percent ?? 0,
        videoKey,
        contentData: selectedVideo.data.contentData,
        refModel: selectedVideo.data.refModel,
      };
      contentData = selectedVideo.data.contentData;
    } else if (selectedVideo === 0 && defaultVideo?._id) {
      const videoKey = `${activeItem?._id}-${defaultVideo._id}`;
      videoData = {
        id: defaultVideo?._id,
        name: defaultVideo?.name,
        percent: videoProgress?.[videoKey] ?? defaultVideo?.percent,
        videoKey,
        contentData: defaultVideo?.contentData,
        refModel: defaultVideo?.refModel,
      };
      contentData = defaultVideo?.contentData;
    }
 
    if (contentData?.file) {
      videoData.type = "file";
      videoData.videoUrl = contentData.featured;
    } else if (contentData?.url) {
      videoData.type = "url";
      videoData.videoUrl = contentData.url;
    }
 
    videoData.courseId = selectedCourseId
    videoData.sectionId = activeItem?._id
 
    setCurrentVideo((prev) => {
      // to stop video and save progress when switching
      if (prev?.refModel !== 'Quiz' && prev.id !== videoData.id) {
        setVideoPlayback(false);
        callProgressApi({ prevVideoKey: prev.videoKey, prevSectionId: prev.sectionId, prevVideoId: prev.id });
      }
      return videoData
    });
 
    if (videoData.refModel !== 'Quiz') {
      currentVideoRef.current = videoData;
    }
 
    // to prevent infinite call of onReady function
    if (selectedVideo?.sectionId && currentVideo?.id && videoData.id !== currentVideo.id) {
      onReadyCalled.current = false;
      setIsVideoLoaded(false);
      setVideoPlayback(false);
    }
  }
 
  useEffect(() => {
      setVideoPlayback(false);
  }, [pdfOpen]);
 
  useEffect(() => {
    if (selectedVideo === 0 || selectedVideo?.sectionId) {
      handleCurrentVideo();
    }
  }, [defaultVideo?._id, selectedVideo?.data?._id, activeItem, selectedCourseId]);
 
  // Quiz Functions
  const toggleInstructionBox = () => {
    setInstructionBox(!showInstructionBox);
  }
 
  // const quizData = defaultVideo?.sectionData?.evaluationTest[0]?.testId

  const startQuiz = () => {
    history.push({
      pathname: "/quiz/" + currentVideo.contentData.mode + "/" + currentVideo.contentData.slug,
      state: currentVideo.contentData,
    });
    setQuizStarted(true);
    setInstructionBox(false);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    dispatch(selfLearningVideoUrl(0));

    return () => {
      onReadyCalled.current = false;
      callProgressApi({ videoRefreshVal: videoRefresh, videoRefreshFn: onVideoRefresh });
    }
  }, [props?.sectionId])

  return {
    playerRef,
    defaultVideo,
    selectedVideo,
    currentVideo,
    videoPlayback,
    setVideoPlayback,
    handleVideoPlayback,
    handleVideoOnReady,
    showPopup,
    closePopup,
    handleResume,
    handleStartFromBeginning,
    callProgressApi,
    defaultVideoPlayerSettings,
    dynamicVideoPlayerSettings,
    showComponent,
    toggleInstructionBox,
    seoSettings,
    dynamicSeoSettings,
    isLoadingSelf,
    selfLearningData,
    startQuiz,
    quizIsStarted,
    isVideoLoaded,
  };
}

export default useSelfLearningContent;
