import React, { useEffect, useState } from "react";
import VideoSelfLearning from "./VideoSelfLearning";
import useSelfLearningContent from "./useSelfLearningContent";
import InstructionBlock from "../../Quiz/InstructionBlock/InstructionBlock";
import { ReactComponent as SelfLearningImage } from "../../../assets/images/no-pdf.svg";
import { useCourseContent, useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useTheme } from "../../../constants/themeProvider";
import { Box, Typography, LinearProgress, useMediaQuery } from "@material-ui/core";
import { ReactComponent as ExpiringClock } from "../../../assets/images/expiringclockcourse.svg";
import moment from "moment";
import { ReactComponent as Live } from "../../../assets/images/livecourse.svg";
import PropTypes from 'prop-types';
import courseHighlight from '../../../assets/images/highlightCourse.png';
import CTabs from "../CTabs";
import SelfLearningCard from "../SelfLearningCard";
import { useDispatch } from "react-redux";
import { getSelfLearningAction } from "examsbook-react-sdk/actions/getSelfLearningAction";
import LiveClassCard from "../LiveClassCard";
import QuizListSection from "../../QuizList/QuizListSection";
import CertificateCard from "../CertificateCard";
import NoData from "../../common/Nodata/noData";

function SelfLearningContent(props) {
  const theme = useTheme();
  const styles = {
    selfLearningImg: {
      '@media (max-width:959px)': {
        height: '18.3rem'
      } 
    },
    courseInfo: {
      background: `${theme?.primaryColor}`,
      borderRadius: '20px',
      padding: '20px',
      backgroundImage: `url(${courseHighlight})`,
      backgroundSize: 'cover'
    },
    courseTitle: {
      color: `${theme?.primaryFontText}`,
      '@media (max-width: 767px)': {
        fontSize: 14
      },
      
    },
    courseText: {
      color: `${theme?.primaryFontText}`,
      fontSize: '14px',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 4,
      overflow: 'hidden',
      display: '-webkit-box',
      margin: 0,
      '& > p': {
        fontSize: '14px',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 4,
        overflow: 'hidden',
        display: '-webkit-box',
        margin: 0,
      }
    },
    tagWrapper: {
      display: 'flex', 
      gap: '10px', 
      marginTop: 15
    },
    alignCourseData: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15
    },
    courseTags: {
      border: `1px solid ${theme?.primaryFontText}`,
      color: theme?.primaryFontText,
      display: 'flex',
      padding: '0px 9px',
      alignItems: 'center',
      borderRadius: '4px',
      marginBottom: '20px',
      cursor: 'default',
      fontSize: '14px'
    },
    progressLayout: {
      borderRadius: '0px !important',
      backgroundColor: '#ffffff54',
      '& > div': {
        backgroundColor: '#FFFFFF !important'
      },
    },
    mainTabHeading: {
      '@media (max-width: 767px)': {
        fontSize: 16
      }
    }
  }
  const classes = useStyles(styles)();
  const t = useTranslations();
  const {
    playerRef,
    defaultVideo,
    currentVideo,
    videoPlayback,
    setVideoPlayback,
    handleVideoPlayback,
    handleVideoOnReady,
    showPopup,
    closePopup,
    handleResume,
    handleStartFromBeginning,
    callProgressApi,
    defaultVideoPlayerSettings,
    dynamicVideoPlayerSettings,
    showComponent,
    toggleInstructionBox,
    startQuiz,
    quizIsStarted,
    isVideoLoaded,
    certificateUnlocked,
    isLoadingSelf,
  } = useSelfLearningContent(props);

  const dispatch = useDispatch();
  const [recordedVideos, setRecordedVideos] = useState([]);
  const [quiz, setQuiz] = useState([]);
  const [pdfNotes, setPdfNotes] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const passingPercent = quiz[0]?.testId?.passingPercent;
  const [refresh, setRefresh] = useState(true);
  const handleRefresh = () => setRefresh(!refresh);
  const { content } = useCourseContent(props?.selectedCourseId, props?.subject_id, props?.topic_id, refresh);
  const { data: courseDetail } = content;
  const [startEvaluationButton, setStartEvaluationButton] = useState(false);

  const [currentActiveSection, setCurrentActiveSection] = useState(props?.activeItem?.data);

  useEffect(() => {
    if (courseDetail && courseDetail.length && courseDetail[0].recordedVideos && courseDetail[0].recordedVideos.length) {
        setRecordedVideos(courseDetail[0].recordedVideos);
    } else {
        setRecordedVideos([]);
    }
    if (props?.mySectionData && props?.mySectionData?.data?.evaluationTest) {
        setQuiz(props?.mySectionData?.data?.evaluationTest);
    } else {
        setQuiz([]);
    }
    if (courseDetail && courseDetail.length && courseDetail[0].pdfNotes && courseDetail[0].pdfNotes.length) {
        setPdfNotes(courseDetail[0].pdfNotes);
    } else {
        setPdfNotes([]);
    }
}, [courseDetail,props?.mySectionData]);

  useEffect(() => {
    getCourseSectionApiData();
  }, [props?.selectedCourseId, props?.videoRefresh, props?.pdfOpen]);

  const getCourseSectionApiData = async () => {
    try {
      if (props?.selectedCourseId !== undefined) {
          if (!props.activeItem && typeof res != "undefined") {
              props.setActiveItem({
                data:props?.mySectionData?.data?.section[0],
                sectionData:props?.mySectionData
              });
          }
      }
    } catch (error) {
        console.log('Error - ', error.message);
    }
  };
  function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className={classes.progressLayout} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="p" style={{ color: '#FFFFFF !important' }}>{`${Math.round(props?.value)}%`}</Typography>
            </Box>
        </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
      value: PropTypes.number.isRequired,
  };

  if (!showComponent) {
    return null;
  }

  if (defaultVideo === undefined) {
    return (
      currentVideo?.refModel === "Quiz" ? (
        <InstructionBlock
          quiz={currentVideo?.contentData}
          quizIsStarted={quizIsStarted}
          toggleInstructionBox={toggleInstructionBox}
          startQuiz={startQuiz}
          certificateUnlocked={certificateUnlocked}
          courseName={props?.courseName}
        />
      ) : <SelfLearningImage alt="default-image" className={classes.selfLearningImg} style={{ width: "100%" }} />
    )
  }

  return (
    <>
    {
      props.showEvaluationTest || props.showInstructions ? <></> :  <Box className={classes.courseInfo}>
      <Box className={classes.alignCourseData}>
        <Typography className={classes.courseTitle} variant="h4">{props?.activeItem?.sectionData?.data?.courseId?.name}</Typography>
        <Box style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
          <ExpiringClock/>
          <Typography style={{fontSize: '12px', color: '#ffffff'}} variant="h6">{`${t('EXPIRING ON')} ${moment(props?.activeItem?.sectionData?.assignedCourseEndDate).format("DD-MM-YYYY")}`}</Typography>
        </Box>
      </Box>
      <Typography className={classes.courseText} variant="h6" dangerouslySetInnerHTML={{ __html: props?.activeItem?.sectionData?.data?.courseId?.description }}></Typography>
      <Box className={classes.tagWrapper}>
        {props?.subjectDetail?.isSelfLearning && <Box className={classes.courseTags}>{t('SELF LEARNING')}</Box>}
        {props?.subjectDetail?.isLive && <Box className={classes.courseTags}><span className='live-image'><Live/></span><span>{t('live')}</span></Box>}
      </Box>
      <Box className='card-title-progress' style={{ minHeight: '26px', color: '#FFFFFF' }}>
          <LinearProgressWithLabel value={props?.coursePercentage} />
      </Box>
    </Box>
    }

    {!props?.showEvaluationTest && <Typography className={classes.mainTabHeading} variant="h5" style={{margin: '30px 0px 10px 0px', fontWeight: '500'}}>{t('Comprehensive Course Structure')}</Typography>}

      <CTabs
        nav={props?.tabBarList}
        setActiveTab={props?.selectedTab}
        handleTabChange={props?.handleTabChange}
        subjectDetail={props?.subjectDetail}
        mySectionData={props?.mySectionData}
        setMySectionData={props?.setMySectionData}
      />
      {props?.selectedIndex === 0 && (
        <>
          {props?.mySectionData?.data?.section !== undefined ? props?.mySectionData?.data?.section.map((item, index) => {
            return(<SelfLearningCard currentActiveSection={currentActiveSection} setCurrentActiveSection={setCurrentActiveSection}
              setActiveItem={props.setActiveItem} activeItem={props?.activeItem} index={index} sectionIndex={index} data={item}
              sectionData={props?.mySectionData} activeIndex={activeIndex} setActiveIndex={setActiveIndex}
              onVideoRefresh={props?.onVideoRefresh} videoRefresh={props?.videoRefresh} pdfOpen={props?.pdfOpen} courseId={props?.activeItem?.sectionData?.data?.courseId?._id}
              onPdfOpen={props.onPdfOpen} 
              setSectionNumber={props?.setSectionNumber}
              setSectionName={props?.setSectionName}
              setNextClick={props?.setNextClick}
              setCourseDataList={props?.setCourseDataList}
              setSelectedSubmenu={props?.setSelectedSubmenu}
              selectedSubmenu={props?.selectedSubmenu}
              currentSectionIndex = {props?.currentSectionIndex}
              isDropdownOpen={props?.isDropdownOpen}
              setIsDropdownOpen={props?.setIsDropdownOpen}
              setCurrentSectionIndex = {props?.setCurrentSectionIndex}
              currentDataIndex = {props?.currentDataIndex}
              setCurrentDataIndex={props?.setCurrentDataIndex}
              setIsDropDown={props?.setIsDropDown}
              sectionChanged={props.sectionChanged} 
              setSectionChanged={props.setSectionChanged}
              showEvaluationTest={props.showEvaluationTest}
              setShowEvaluationTest={props.setShowEvaluationTest}
              sectionName={props.sectionName}
              handleUpdateCourseProgress={props?.handleUpdateCourseProgress}
              />)
          }) : !isLoadingSelf && props?.mySectionData?.data?.section?.length() < 1 && <>{t('No Self Learning data found!')}</>}    
        </>
      )}

      {props?.selectedIndex === 1 && (
        <>
          <Box>
              {props?.mySectionData?.data?.liveClass?.length ? props?.mySectionData?.data?.liveClass.map((item, index) => {
                return <LiveClassCard key={index} data={item} sectionData={props?.mySectionData} />
              }) : !isLoadingSelf && props?.mySectionData?.data?.section?.length() < 1 && <>{t('No Live Class Found!')}</>}
          </Box>
        </>
      )}

      {props?.selectedIndex === 2 && (
        <>
          <Box>
              {quiz?.length ? <Box>
                  {quiz.length ? (
                  <QuizListSection
                      hideViewMore={false}
                      quizSectionList={quiz}
                      showVertical={true}
                      handleRefresh={handleRefresh}
                      startQuiz={startQuiz}
                      course_id={props?.selectedCourseId}
                      onQuizCardClick={props?.onQuizCardClick}
                      showInstructions={props?.showInstructions}
                      showInstructionScreen={props?.showInstructionScreen}
                  />
                  ) : (
                  <NoData message={t("no_data")} />
                  )}
              </Box> : !isLoadingSelf && props?.mySectionData?.data?.section?.length() < 1 && <>{t('No Evaluation Test Found!')}</>}
          </Box>
        </>
      )}

      {props?.selectedIndex === 3 && (
        <>
          <CertificateCard setdownloadCertificateButton={props?.setdownloadCertificateButton} downloadCertificateButton={props?.downloadCertificateButton} sectionData={props?.mySectionData} />
        </>
      )}
      
      {/* Video */}
      {currentVideo?.refModel === "RecordedVideo" && (
        <VideoSelfLearning 
          playerRef={playerRef}
          currentVideo={currentVideo}
          videoPlayback={videoPlayback}
          setVideoPlayback={setVideoPlayback}
          handleVideoPlayback={handleVideoPlayback}
          handleVideoOnReady={handleVideoOnReady}
          defaultVideoPlayerSettings={defaultVideoPlayerSettings}
          dynamicVideoPlayerSettings={dynamicVideoPlayerSettings}
          showPopup={showPopup}
          closePopup={closePopup}
          handleResume={handleResume}
          handleStartFromBeginning={handleStartFromBeginning}
          callProgressApi={callProgressApi}
          isVideoLoaded={isVideoLoaded}
          thumbnail={<SelfLearningImage alt="default-image" style={{ width: "100%" }} />}
        />
      )}
      {/* Quiz Instruction */}
      {currentVideo?.refModel === "Quiz" && (
        <InstructionBlock
          quiz={currentVideo?.contentData}
          quizIsStarted={quizIsStarted}
          toggleInstructionBox={toggleInstructionBox}
          startQuiz={startQuiz}
          certificateUnlocked={certificateUnlocked}
          startEvaluationButton={startEvaluationButton}
        />
      )}
    </>
  );
}

export default SelfLearningContent;
